<template>
  <div class="card">
    <div class="card__header">
        <h6 class="card__header__tittle">
          <b-icon icon="geo-alt-fill" aria-hidden="true" v-if="tittle !== 'Load'">
          </b-icon>
          <b>
            {{tittle}}
          </b>
        </h6>
    </div>
    <div class="card__content">
        <div v-for="(item, index) in data" :key="index">
            <b-form-checkbox
            class="card__content__checkbox"
            :id="tittle + index"
            v-model="selectedOptions[index]"
            :name="'label' + index"
            :value="item.id"
            >
            {{item.accessorial_name}}
            </b-form-checkbox>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Card',
  props: {
    tittle: {
      type: String,
    },
    data: {
      type: Array,
    },
  },
  data() {
    return {
      selectedOptions: [],
    };
  },
  created() {
    if (this.$route.params.resetInfo) {
      this.selectedOptions = [];
      this.modifyData();
      this.selectedOptions.length = this.data.length;
      this.selectedOptions.fill(false);
    } else {
      this.getAllInfo();
    }
  },
  methods: {
    getAccessorials() {
      this.modifyData();
      return this.selectedOptions;
    },
    modifyData() {
      if (this.tittle === 'Pickup') {
        this.$store.commit('scale/setPickupData', this.selectedOptions);
      } else if (this.tittle === 'Delivery') {
        this.$store.commit('scale/setDeliveryData', this.selectedOptions);
      } else {
        this.$store.commit('scale/setLoadData', this.selectedOptions);
      }
    },
    getAllInfo() {
      if (this.tittle === 'Pickup') {
        this.selectedOptions = this.$store.getters['scale/showPickupData'];
      } else if (this.tittle === 'Delivery') {
        this.selectedOptions = this.$store.getters['scale/showDeliveryData'];
      } else {
        this.selectedOptions = this.$store.getters['scale/showLoadData'];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  @include card;
  &__header {
    width: 100%;
    margin: 0.5rem 0;
    &__tittle {
      color: $color-primary-button;
    }
  }
  &__content {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &__checkbox {
      margin: 0 0.5rem;
    }
  }
}
</style>
